<template>
  <Sidebar
    ref="sidebar"
    header="Dependent fields"
    @submit="submit"
    @hidden="clearField"
  >
    <div class="my-3" v-if="isShow">
      <b-row class="align-items-center">
        <b-col cols="6" sm="3">
          <InputText
            textFloat="Field Name"
            disabled
            v-model="fields.choice_dependent_name"
            :placeholder="fields.choice_dependent_name"
            class="mb-3"
            type="text"
        /></b-col>
        <b-col cols="6" sm="3">
          <InputText
            textFloat="Description"
            disabled
            value="ประเภทธุรกิจ"
            :placeholder="fields.choice_dependent_name"
            class="mb-3"
            type="text"
        /></b-col>
        <div class="break-normal"></div>
        <b-col cols="1"> Sort Order</b-col>
        <b-col cols="2"> Field Name</b-col>
        <b-col cols="2"> Description</b-col>
        <b-col cols="1"> </b-col>

        <div class="break-normal"></div>
        <template v-for="(dependent_choice, index) of fields.level3">
          <b-col
            cols="1"
            :key="'sort-dependent-' + dependent_choice.id + index"
            class="mb-3"
          >
            <div class="content-between">
              <font-awesome-icon
                icon="chevron-circle-up"
                :class="`icon-size `"
                @click="sortIndex(dependent_choice, index, false)"
              />
              {{ index + 1 }}
              <font-awesome-icon
                icon="chevron-circle-down"
                :class="`icon-size `"
                @click="sortIndex(dependent_choice, index, true)"
              />
            </div>
          </b-col>
          <b-col
            cols="2"
            :key="'name-dependent-' + dependent_choice.id + index"
            class="mb-3"
          >
            <InputText
              textFloat=""
              v-model="dependent_choice.choice_dependent_name"
              :placeholder="dependent_choice.choice_dependent_name"
              @input="updateField(dependent_choice)"
              type="text"
          /></b-col>
          <b-col
            cols="2"
            :key="'desc-dependent-' + dependent_choice.id + index"
            class="mb-3"
          >
            <InputText
              textFloat=""
              disabled
              value="คำนำหน้า"
              placeholder=""
              type="text"
          /></b-col>
          <b-col
            cols="1"
            :key="'action-dependent-' + dependent_choice.id + index"
            class="text-center mb-3"
          >
            <font-awesome-icon
              @click="removeField(index)"
              :icon="['fas', 'trash-alt']"
            ></font-awesome-icon>
          </b-col>

          <div
            class="break-normal"
            :key="'line-break' + dependent_choice.id + index"
          ></div>
        </template>
        <b-col cols="1"></b-col>
        <b-col cols="2"
          ><div class="content-between">
            <div
              class="cursor-pointer color-primary text-underline"
              @click="addField(1)"
            >
              Add Field
            </div>
            <div
              class="cursor-pointer color-primary text-underline"
              @click="addField(3)"
              v-if="
                fields.level3.filter((el) => el.choice_type_id == 3).length == 0
              "
            >
              Add Other Field
            </div>
          </div></b-col
        >
      </b-row>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from "./Sidebar";
export default {
  name: "sidebarDependentCompany",
  components: { Sidebar },
  props: {
    choiceDetail: { required: true, type: Object },
  },
  data() {
    return {
      isShow: false,
      fields: null,
    };
  },
  methods: {
    show() {
      this.fields = JSON.parse(JSON.stringify(this.choiceDetail));
      this.fields.level3 = this.fields.level3.sort(
        (a, b) => a.sort_order - b.sort_order
      );
      this.$refs.sidebar.show();
      this.isShow = true;
    },
    submit() {
      this.fields.delete = this.choiceDetail.level3
        .filter((el) => !this.fields.level3.find((del) => del.id == el.id))
        .map((el) => el.id);

      this.$emit("addDependent", this.fields);
      this.$refs.sidebar.hide();
      this.isShow = false;
    },
    clearField() {
      this.isShow = false;
    },
    sortIndex(items, index, val) {
      if (index == 0 && !val) {
        return;
      }

      this.fields.level3.splice(index, 1);
      index = val ? index + 1 : index - 1;

      this.fields.level3.splice(index, 0, items);
      var index = 0;
      for (const fields of this.fields.level3) {
        fields.is_update = 1;
        fields.sort_order = index;
        index++;
      }
      this.$nextTick(() => {
        this.fields.level3 = [...this.fields.level3];
      });
    },
    addField(type) {
      let fields = JSON.parse(JSON.stringify(this.fields));

      fields.level3.push({
        id: 0,
        fix_field_choice_level2: 0,
        field_id: 0,
        billing_field_id: 0,
        shipping_field_id: 0,
        contact_field_id: 0,
        company_field_id: 0,
        choice_main: 0,
        is_update: 0,
        choice_dependent: 0,
        choice_type_id: type,
        is_display: 1,
        sort_order: fields.level3.length,
        choice_dependent_name:
          type == 3 ? "อื่นๆ" : `Choice ${fields.level3.length + 1}`,
        level3: null,
        delete: null,
      });

      this.fields = fields;
    },
    removeField(index) {
      this.fields.level3.splice(index, 1);
    },
    updateField(field) {
      if (field.id != 0) field.is_update = 1;
    },
  },
};
</script>

<style></style>
